Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.getApiMethod = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ProjectTemplates";
exports.labelBodyText = "ProjectTemplates Body";
exports.projectAPiEndPoint = "/bx_block_catalogue/studies/study_selection";
exports.StrongemailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
exports.btnExampleTitle = "CLICK ME";
exports.phoneNumberRegex =  /^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/ ;
exports.nameRegex = /^[a-zA-Z\s]+$/;
exports.getUserAccountApiEndPoint = "profile/user_profiles"
exports.passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;
exports.changePasswordApiEndPoint = "bx_block_profile/new_password_set"
exports.putApiMethod = "PUT";
exports.getTermsAndConditionApiEndPoint = "bx_block_terms_and_conditions/terms_and_conditions"
exports.apiContentType = "application/json";
exports.getNewPrivacyPolicyApiEndPoint = "bx_block_content_management/privacy_policies"
exports.getFaqsApiEndPoint = "bx_block_content_management/faqs"
exports.patchApiMethod = "PATCH"
exports.editUserProfileApiEnd = "bx_block_profile/profiles/update_profile"
exports.changeEmailApiEndPoint = "bx_block_profile/change_email"
exports.postApiMethod="POST"
exports.getStudyApiEndPoint = "bx_block_catalogue/studies/study_selection"
exports.getContactUsApiEndPoint = "bx_block_contact_us/contacts"
exports.translations = {
  fr: {
    phoneNotAlert: "Le numéro de téléphone n'est pas disponible",
    SelectStudy : "Sélectionner l'étude",
    StudyNumber: "Numéro d'étude",
    NoStudyFound: "Aucune étude trouvée",
    SearchStudyName: "Search Study Name", //n
    NoMatchFound: "No match found.", 
    
    ChangePassword : "Changer le mot de passe",
    ChangePasswordMsg: "Veuillez entrer votre nouveau mot de passe pour réinitialiser",
    EnterOldPassword : "Entrez l'ancien mot de passe",
    EnterNewPassword : "Entrer un nouveau mot de passe",
    ConfirmNewPassword: "Confirmer le nouveau mot de passe",
    UpdatePassword: "Mettre à jour le mot de passe",
    ChangeEmailRequest : "Modifier la demande par email",
    ChangeEmailReqMsg : "Veuillez saisir votre adresse e-mail pour réinitialiser",
    EnterOldEmail : "Entrer l'ancien email",
    EnterNewEmail : "Entrez une nouvelle adresse email",
    ConfirmNewEmail : "Confirmer le nouvel email",
    SendRequest : "Envoyer une demande",
    Profile: "Profil",
    FullName : "Nom et prénom",
    Email : "Adresse email",
    Phone: "Numéro de téléphone",
    Address: "Adresse",
    TechnicalHelp: "Aide technique",
    EditProfile: "Modifier le profil",
    ChangeProfilePicture : "Changer la photo de profil",
    ImageUploadType : "Please upload valid image type.",
    EmailAddress: "Adresse email",
    PhoneNumber: "Numéro de téléphone",
    UpdateDetails: "Mettre à jour les détails",
    ContactUs : "Contactez-nous",
    TalkCustomerSupport : "Parlez avec notre service client",
    TalkCustomerSupportDetails: "Contactez-nous pour obtenir de l’aide, des demandes de renseignements ou des commentaires. Nous sommes là pour vous aider !",
    phoneNumberDigits: "+91 54321 1235",   
    AvailableTime: "Available 9:00 AM to 8:00 PM",
    SendYourQueries: "Envoyez vos requêtes!",
    Reason : "Raison",
    ReasonPlaceholder : "Entrez la raison",
    Message : "Message",
    TypeYourMsg : "Tapez votre message ici",
    Submit: "Soumettre",
    TandC : "Termes & Conditions",
    PrivacyPolicy : "Politique de confidentialité",
    Logout : "Se déconnecter",
    LogoutMsg : "Etes-vous sûr de vouloir vous déconnecter ?",
    Yes: "Oui",
    No: "Non",
    SessionExpiredMsg1 : "Your session has expired",
    SessionExpiredMsg2 : " please login again.",
    Okay: "Okay",
    Study: "Etude",


    oldPasswordErrorMsg: "Le mot de passe doit comporter au maximum 15 caractères.",
    oldPasswordErrorMsg2: "L'ancien mot de passe n'est pas valide !",
    passwordMsgValidation: "Le mot de passe doit contenir 1 lettre majuscule, 1 lettre minuscule, 1 caractère spécial, 1 chiffre numérique et comporter entre 8 et 15 caractères!",
    passwordMatchErrorMsg : "Le mot de passe ne correspond pas",
    oldPasswordErrorMsg3 : "Veuillez saisir l'ancien mot de passe",
    oldPasswordErrorMsg4: "Veuillez entrer le mot de passe",
    passwordMsg : "Veuillez saisir le mot de passe confirmé",
    passwordMsg2 : "Veuillez saisir un nouveau mot de passe différent du précédent.",
    EmailErrorMsg: "L'e-mail doit être valide !",
    EmailErrorMsg2: "L'ancien e-mail n'est pas valide ! L'email ne correspond pas !",
    EmailErrorMsg3: "L'email ne correspond pas !",
    EmailErrorMsg5 : "Veuillez entrer un email valide",
    EmailValidationMsg: '​veuillez entrer votre email',
    EmailValidationMsg2: "veuillez entrer l'e-mail de confirmation",
    EmailValidationMsg3: 'veuillez entrer votre ancien email',
    phoneNumberValidationMsg: 'Veuillez entrer un numéro de téléphone valide',
    FullNameValidationMsg: 'Veuillez saisir un nom complet valide',
    SelectReasonValidationMsg: "Veuillez sélectionner une raison",

    'change-password': 'Changer le mot de passe',
    'terms-condt': 'Termes & Conditions',
    'privacy-policy': 'Politique de Confidentialité',
    'tech-help': 'Aide Technique',
    'contact-us': 'Contactez-nous',
    'logout': 'Se Déconnecter',
    RequestAdmin : 'Demande administrateur',
    AddressLine: 'Adresse Ligne'
  },
  en:{
    phoneNotAlert: "Phone number is not available",
    SelectStudy : "Select the study",
    StudyNumber: "Study Number",
    NoStudyFound: "No Study Found",
    SearchStudyName: "Search Study Name",
    NoMatchFound: "No match found.",
    
    ChangePassword : "Change Password",
    ChangePasswordMsg: "Please enter your new password to reset.",
    EnterOldPassword : "Enter Old Password",
    EnterNewPassword : "Enter New Password",
    ConfirmNewPassword: "Confirm New Password",
    UpdatePassword: "Update Password",
    ChangeEmailRequest : "Change Email Request",
    ChangeEmailReqMsg : "Please enter your new email address to reset.",
    EnterOldEmail : "Enter Old Email",
    EnterNewEmail : "Enter New Email",
    ConfirmNewEmail : "Confirm New Email",
    SendRequest : "Send Request",
    Profile: "Profile",
    FullName : "Full Name",
    Email : "Email",
    Phone: "Phone",
    Address: "Address",
    TechnicalHelp: "Technical Help",
    EditProfile: "Edit Profile",
    ChangeProfilePicture : "Change profile picture",
    ImageUploadType : "Please upload valid image type.",
    EmailAddress: "Email Address",
    PhoneNumber: "Phone Number",
    UpdateDetails: "Update Details",
    ContactUs : "Contact Us",
    TalkCustomerSupport : "Talk with our Customer Support",
    TalkCustomerSupportDetails: "Get in touch with us for assistance, inquiries, or feedback. We’re here to help!",
    phoneNumberDigits: "+91 54321 1235",   
    AvailableTime: "Available 9:00 AM to 8:00 PM",
    SendYourQueries: "Send your queries!",
    Reason : "Reason",
    ReasonPlaceholder : "Enter reason",
    Message : "Message",
    TypeYourMsg : "Type your message here",
    Submit: "Submit",
    TandC : "Terms and Conditions",
    PrivacyPolicy : "Privacy Policies",
    Logout : "Logout",
    LogoutMsg : "Are you sure you want to Logout ?",
    Yes: "Yes",
    No: "No",
    SessionExpiredMsg1 : "Your session has expired",
    SessionExpiredMsg2 : " please login again.",
    Okay: "Okay",
    Study: "Study",


    oldPasswordErrorMsg: "Password must be at most 15 characters long!",
    oldPasswordErrorMsg2: "Old password is invalid!",
    passwordMsgValidation: "Password must contain 1 uppercase, 1 lowercase, 1 special character, 1 numeric digit, and be 8-15 characters long!",
    passwordMatchErrorMsg : "Password do not match",
    oldPasswordErrorMsg3 : "please enter old password",
    oldPasswordErrorMsg4: "please enter password",
    passwordMsg : "please enter confirm password",
    passwordMsg2 : "Please enter a new password different from the previous one.",
    EmailErrorMsg: "Email must be valid!",
    EmailErrorMsg2: "Old Email is invalid!",
    EmailErrorMsg3: 'Email does not match!',
    EmailErrorMsg5 : "Please enter a valid email",
    EmailValidationMsg: 'please enter email',
    EmailValidationMsg2: 'please enter confirm email',
    EmailValidationMsg3: 'please enter old email',
    phoneNumberValidationMsg: 'Please enter a valid phone number',
    FullNameValidationMsg: 'Please enter a valid full name',
    SelectReasonValidationMsg: "Please select a reason",

    'change-password': 'Change Password',
    'terms-condt': 'Terms and Conditions',
    'privacy-policy': 'Privacy Policies',
    'tech-help': 'Technical Help',
    'contact-us': 'Contact Us',
    'logout': 'Logout',
    RequestAdmin : 'Request Admin',
    AddressLine: 'Address Line'
    },
  es: {
    phoneNotAlert:"El número de teléfono no está disponible",
    SelectStudy : "Seleccionar estudio",
    StudyNumber: "Número de estudio",
    NoStudyFound: "No se encontró ningún estudio",
    SearchStudyName: "Search Study Name", //n
    NoMatchFound: "No match found.", //n
    
    ChangePassword : "Cambiar la contraseña",
    ChangePasswordMsg: "Por favor ingrese su nueva contraseña para restablecerla",
    EnterOldPassword : "Ingresa la contraseña anterior",
    EnterNewPassword : "Introduzca nueva contraseña",
    ConfirmNewPassword: "Confirmar nueva contraseña",
    UpdatePassword: "Actualizar contraseña",
    ChangeEmailRequest : "Solicitud de cambio de correo electrónico",
    ChangeEmailReqMsg : "Por favor ingrese dirección de correo electronico para restablecer",
    EnterOldEmail : "Introducir correo electrónico antiguo",
    EnterNewEmail : "Introducir nuevo correo electrónico",
    ConfirmNewEmail : "Confírmar nuevo correo electrónico",
    SendRequest : "Enviar solicitud",
    Profile: "Perfil",
    FullName : "Nombre completo",
    Email : "Correo electrónico",
    Phone: "Número de teléfono",
    Address: "DIRECCIÓN",
    TechnicalHelp: "Ayuda técnica",
    EditProfile: "Editar perfil",
    ChangeProfilePicture : "Cambiar foto de perfil",
    ImageUploadType : "Please upload valid image type.", //n
    EmailAddress: "Correo electrónico",
    PhoneNumber: "Número de teléfono",
    UpdateDetails: "Actualizar detalles",
    ContactUs : "Contacta con nosotros",
    TalkCustomerSupport : "Hable con nuestro equipo de atención al cliente",
    TalkCustomerSupportDetails: "Póngase en contacto con nosotros para obtener ayuda, consultas o comentarios. ¡Estamos aquí para ayudar!",
    phoneNumberDigits: "+91 54321 1235",   
    AvailableTime: "Available 9:00 AM to 8:00 PM",
    SendYourQueries: "Envía tus consultas!",
    Reason : "Razón",
    ReasonPlaceholder : "Introducir motivo",
    Message : "Mensaje",
    TypeYourMsg : "Escribe tu mensaje aquí",
    Submit: "Entregar",
    TandC : "Términos y condiciones",
    PrivacyPolicy : "Políticas de privacidad",
    Logout : "Cerrar sesión",
    LogoutMsg : "¿Está seguro de que desea cerrar sesión?",
    Yes: "Sí",
    No: "No",
    SessionExpiredMsg1 : "Your session has expired",
    SessionExpiredMsg2 : " please login again.",
    Okay: "Okay",
    Study: "Estudio",


    oldPasswordErrorMsg: "La contraseña debe tener como máximo 15 caracteres!",
    oldPasswordErrorMsg2: "¡La contraseña anterior no es válida!",
    passwordMsgValidation: "La contraseña debe contener 1 letra mayúscula, 1 letra minúscula 1 carácter especial, 1 dígito numérico y tener entre 8 y 15 caracteres de longitud",
    passwordMatchErrorMsg : "La contraseña no coincide",
    oldPasswordErrorMsg3 : "Por favor ingrese la contraseña anterior",
    oldPasswordErrorMsg4: "Por favor ingrese la contraseña",
    passwordMsg : "Por favor ingrese confirmar contraseña",
    passwordMsg2 : "Por favor ingrese una nueva contraseña diferente a la anterior.",
    EmailErrorMsg: "¡El correo electrónico debe ser válido!",
    EmailErrorMsg2: "¡El correo electrónico antiguo no es válido!",
    EmailErrorMsg3: "¡El correo electrónico no coincide!",
    EmailErrorMsg5 : "Por favor introduce un correo electrónico válido",
    EmailValidationMsg: 'por favor ingrese el correo electrónico',
    EmailValidationMsg2: 'por favor ingrese el correo electrónico de confirmación',
    EmailValidationMsg3: 'por favor ingrese el correo electrónico antiguo',
    phoneNumberValidationMsg: 'Por favor ingresa un número de teléfono válido',
    FullNameValidationMsg: 'Por favor ingresa un nombre completo válido',
    SelectReasonValidationMsg: "Por favor seleccione un motivo",

    'change-password': 'Cambiar Contraseña',
    'terms-condt': 'Términos y Condiciones',
    'privacy-policy': 'Políticas de Privacidad',
    'tech-help': 'Ayuda Técnica',
    'contact-us': 'Contáctenos',
    'logout': 'Cerrar Sesión',
    RequestAdmin : 'Solicitud de administrador',
    AddressLine: 'Dirección Línea'
  },
  de: {
    phoneNotAlert : "Telefonnummer ist nicht verfügbar",
    SelectStudy : "Wählen Sie die Studie",
    StudyNumber: "Studiennummer",
    NoStudyFound: "keine Studie gefunden",
    SearchStudyName: "Search Study Name",
    NoMatchFound: "No match found.",
    
    ChangePassword : "Kennwort ändern",
    ChangePasswordMsg: "Bitte geben Sie zum Zurücksetzen Ihr neues Passwort ein.",
    EnterOldPassword : "Altes Passwort eingeben",
    EnterNewPassword : "Neue Passwort eingeben",
    ConfirmNewPassword: "Neues Passwort bestätigen",
    UpdatePassword: "Kennwort aktualisieren",
    ChangeEmailRequest : "E-Mail-Anfrage ändern",
    ChangeEmailReqMsg : "Bitte geben Sie zum Zurücksetzen Ihre neue E-Mail-Adresse ein.",
    EnterOldEmail : "Alte E-Mail eingeben",
    EnterNewEmail : "Neue E-Mail eingeben",
    ConfirmNewEmail : "Nuer E-Mail bestätigen",
    SendRequest : "Anfrage senden",
    Profile: "Profil",
    FullName : "Vollständiger Name",
    Email : "E-Mail-Adresse",
    Phone: "Telefonnummer",
    Address: "Adresse",
    TechnicalHelp: "Technische Hilfe",
    EditProfile: "Profil bearbeiten",
    ChangeProfilePicture : "Profilbild ändern",
    ImageUploadType : "Please upload valid image type.",
    EmailAddress: "E-Mail-Adresse",
    PhoneNumber: "Telefonnummer",
    UpdateDetails: "Details aktualisieren",
    ContactUs : "Kontaktiere uns",
    TalkCustomerSupport : "Kunden-Support",
    TalkCustomerSupportDetails: "Kontaktieren Sie uns für Hilfe, Anfragen oder Feedback. Wir sind hier, um zu helfen!",
    phoneNumberDigits: "+91 54321 1235",   
    AvailableTime: "Available 9:00 AM to 8:00 PM",
    SendYourQueries: "Senden Sie Ihre Anfragen!",
    Reason : "Grund",
    ReasonPlaceholder : "Geben Sie Ihre Begründung bekannt",
    Message : "Nachricht",
    TypeYourMsg : "Geben Sie hier Ihre Nachricht ein",
    Submit: "Einreichen/ Abschicken",
    TandC : "Geschäftsbedingungen",
    PrivacyPolicy : "Datenschutzrichtlinie",
    Logout : "Ausloggen",
    LogoutMsg : "Sind Sie sicher, dass Sie sich abmelden möchten?",
    Yes: "Ja",
    No: "NEIN",
    SessionExpiredMsg1 : "Your session has expired",
    SessionExpiredMsg2 : " please login again.",
    Okay: "Okay",
    Study: "Studie",


    oldPasswordErrorMsg: "das Passwort darf höchstens 15 Zeichen lang sein!",
    oldPasswordErrorMsg2: "Altes Passwort ist ungültig!",
    passwordMsgValidation: "das Passwort muss 1 Großbuchstaben, 1 Kleinbuchstaben, ein Sonderzeichen und 1 Ziffer enthalten und 8-15 Zeichen lang sein",
    passwordMatchErrorMsg : "Passwort stimmt nicht überein",
    oldPasswordErrorMsg3 : "Bitte geben Sie das alte Passwort ein",
    oldPasswordErrorMsg4: "Bitte geben Sie das Passwort ein",
    passwordMsg : "Bitte geben Sie das Bestätigungspasswort ein",
    passwordMsg2 : "Bitte geben Sie ein neues Passwort ein, das sich vom vorherigen unterscheidet.",
    EmailErrorMsg: "E-Mail muss gültig sein!",
    EmailErrorMsg2: "Die alte E-Mail ist ungültig!",
    EmailErrorMsg3: "E-Mail stimmt nicht überein!",
    EmailErrorMsg5 : "Bitte geben Sie eine gültige E-Mail-Adresse ein",
    EmailValidationMsg: 'Bitte geben Sie Ihre E-Mail-Adresse ein',
    EmailValidationMsg2: 'Bitte geben Sie eine Bestätigungs-E-Mail ein',
    EmailValidationMsg3: 'Bitte geben Sie die alte E-Mail-Adresse ein',
    phoneNumberValidationMsg: 'Bitte geben Sie eine gültige Telefonnummer ein',
    FullNameValidationMsg: 'Bitte geben Sie einen gültigen vollständigen Namen ein',
    SelectReasonValidationMsg: "Bitte wählen Sie einen Grund aus",

    'change-password': 'Passwort ändern',
    'terms-condt': 'Geschäftsbedingungen',
    'privacy-policy': 'Datenschutzrichtlinie',
    'tech-help': 'Technische Hilfe',
    'contact-us': 'Kontaktieren Sie uns',
    'logout': 'Ausloggen',
    RequestAdmin : 'Anfrage-Administrator',
    AddressLine: 'Adresszeile'
  }
}


// Customizable Area End